<!-- @format -->
<template>
  <b-card title="视频添加">
    <validation-observer ref="createProdBrand">
      <b-form>
        <b-row>
          <b-col md="4">
            <b-form-group label-align="center" label="视频标题" label-cols="1" label-cols-md="3" content-cols="6" content-cols-md="8">
              <validation-provider #default="{ errors }" name="视频标题" rules="required">
                <b-form-input v-model="formData.title" :state="errors.length > 0 ? false : null" placeholder="请输入视频名称" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-align="center" label="视频地址" label-cols="1" label-cols-md="3" content-cols="6" content-cols-md="8">
              <validation-provider #default="{ errors }" name="视频标题" rules="required">
                <b-form-input v-model="formData.url" :state="errors.length > 0 ? false : null" placeholder="请输入视频名称" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-align="center" label="视频状态" label-cols="1" label-cols-md="3" content-cols="6" content-cols-md="8">
              <b-form-radio-group
                v-model="formData.status"
                :options="[
                  {
                    text: '启用',
                    value: 1,
                  },
                  {
                    text: '禁用',
                    value: 0,
                  },
                ]"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="primary" type="submit" @click.prevent="validationForm" :disabled="isRequest"> 提交 </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { ref, onUnmounted, getCurrentInstance, computed } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import vSelect from 'vue-select'
import { required, url } from '@core/utils/validations/validations'
import WangEditor from '@/views/GeneralManagement/WebManager/Article/WangEditor'

import store from '@/store'
import axiosIns from '@/libs/axios'
import ClassManagerModule from './VideoModule'

export default {
  name: 'VideoeEdit',
  components: {
    BCard,
    vSelect,
    WangEditor,
  },
  setup() {
    const contentHtml = val => {
      console.log(val)
    }

    const INVOICE_APP_STORE_MODULE_NAME = 'article-module'
    const { router } = useRouter()
    const { proxy } = getCurrentInstance()

    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, ClassManagerModule)
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
    const isRequest = ref(false)
    const formData = computed(() => store.state[INVOICE_APP_STORE_MODULE_NAME].detailArticle)
    const createProdBrand = ref(null)

    const validationForm = () => {
      isRequest.value = true
      createProdBrand.value
        .validate()
        .then(res => {
          if (res) {
            const data = {
              id: formData.value.id,
              title: formData.value.title,
              url: formData.value.url,
              // cate_id: formData.value.cate_id,
              status: formData.value.status,
            }
            store.dispatch('article-module/editVideo', data).then(response => {
              if (response.code === 0) {
                proxy.$bvToast.toast(response.msg, {
                  title: '提示',
                  variant: 'success',
                  solid: true,
                })
                setTimeout(() => {
                  isRequest.value = false
                  router.back()
                }, 2000)
              } else {
                proxy.$bvToast.toast(response.msg, {
                  title: '提示',
                  variant: 'success',
                  solid: true,
                })
              }
            })
          } else {
            isRequest.value = false
          }
        })
        .catch(() => {
          isRequest.value = false
          console.log('validationForm error')
        })
    }

    // 获取视频分类
    const articleCate = ref([])

    const catePage = ref(1)
    const catePageSize = ref(10)
    const cateTotal = ref(0)
    const hasPrevpage = computed(() => catePage.value > 1)
    const hasNextpage = computed(() => catePage.value * catePageSize.value < cateTotal.value)
    axiosIns.get('/article/getArticleCateList', { params: { pageSize: 10 } }).then(response => {
      const { list, total } = response.data
      articleCate.value = list
      cateTotal.value = total
    })
    const searchCate = (search, loading) => {
      if (search) {
        loading(true)
        axiosIns.get('/article/getArticleCateList', { params: { pageSize: 10, title: search } }).then(response => {
          const { list, total } = response.data
          articleCate.value = list
          cateTotal.value = total
          loading(false)
        })
      }
    }

    return {
      validationForm,
      contentHtml,
      formData,
      createProdBrand,

      isRequest,
      required,
      url,
      catePage,
      articleCate,
      hasPrevpage,
      hasNextpage,
      searchCate,
    }
  },
}
</script>

<style scoped>
.style-chooser .vs__search::placeholder {
  color: #ccc;
}
.style-chooser .vs__dropdown-toggle {
  border: 1px solid red;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}
</style>
